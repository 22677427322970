/*---------------------------------------*\
   Variables
\*---------------------------------------*/
// Colors
@white: #FFFFFF;
@primary: #1cb5e0;
@secondary: #000046;
@orange: #f2bb17;
@lgrey: #ebebeb;
@txtcolor: #565657;

// Transition Time
@time: 0.3s;
// Transition Ease
@ease: linear;
// Base font type
@type: sans-serif;
// Base font size for REM calculate
@bs: 16;
// Base Line height
@lh: 10;

// Responsive breakpoints
@screen_mobile: 480px;
@screen_mobile_max: (480 - 1)*1px;
@screen_tablet: 768px;
@screen_tablet_max: (768 - 1)*1px;
@screen_desktop: 992px;
@screen_desktop_max: (992 - 1)*1px;
@screen_large: 1200px;
@screen_large_max: (1200 - 1)*1px;

// Offset calculate
// BS Grid has REM offsets
@gutter: 2rem;
@gutter_px: (@bs * 2)*1px;
@offset: (@gutter/2);
@offset-xs: 5px;
@offset-sm: 10px;
@offset-md: (@offset*2);
@offset-lg: (@offset*4);
@offset-xl: (@offset*6);
@offset-xxl: (@offset*8);
@offset-xxxl: (@offset*10);

// Container has PX width
@container-tablet: (720px + @gutter_px);
@container-sm: @container-tablet;
@container-desktop: (940px + @gutter_px);
@container-md: @container-desktop;
@container-large-desktop: (1140px + @gutter_px);
@container-lg: @container-large-desktop;

/*---------------------------------------*\
   Fonts
\*---------------------------------------*/

.ff_sintony() {
  font-family: 'Sintony', sans-serif;
}
.ff_alegreya() {
  font-family: 'Alegreya', serif;
}
/*---------------------------------------*\
   Rulesets
\*---------------------------------------*/
.hover(@rules) {
  .hover-on & {
    &:hover {
      @rules();
    }
  }
};
.transitions(@rules) {
  .transition-on & {
    @rules();
  }
};

.fs(@value, @lh: @lh) {
  font-size: (@value / @bs) * 1rem;
  line-height: (@value + @lh) / @bs * 1rem;
}
.fs_px(@value, @lh: @lh) {
  font-size: (@value) * 1px;
  line-height: (@value + @lh) * 1px;
}
.btn_color(@color){
  cursor:pointer;
  display:inline-block;
  background: @color;
  color: @white;
  border: 2px solid @color;
  .hover({ background: darken(@color, 5%); border-color: darken(@color, 5%); color: @white; });
  &:active, &:focus {
    background: darken(@color, 10%);
    border-color: darken(@color, 10%);
    color: @white;
  }
}
