// Assets
@import (reference) "_includes/_vars.less";
// Extends
@import (reference) "_includes/_mixins.less";
// Normalize
@import "_includes/normalize.less";
// Bootstrap Grid
@import "_includes/grid.less";
// Custom Fonts
@import "_includes/_fonts.less";

/*---------------------------------------*\
   Base Styles
\*---------------------------------------*/
html, body {

  width: 100%;
  height: 100%;

}
html {

  font-size: @bs*1px;

}
body {
  overflow-x: hidden;
  .ff_sintony();
  font-size: 1rem;
  color: @secondary;
  overflow: auto;
  //opacity: 0;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 99999999;
    background-color: @white;
    transition: all 0.8s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &.loaded {
    backface-visibility: hidden;
    &:before {
      opacity: 0;
      z-index: -1;
    }
  }
}

.wrap, .contentinfo {
  &:extend(.clear);
}

.wrapper {
  overflow: hidden;
}

/*---------------------------------------*\
   Box Config
\*---------------------------------------*/
* {
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
*, *:before, *:after {
  box-sizing: border-box;
}
.container, aside, header, section {
  position: relative;
}
div, article, section, aside, main {
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.container {
  &:extend(.container all);
}

/*---------------------------------------*\
   INIT Mixins
\*---------------------------------------*/
// Reset Outlines
.outline-reset();

/*---------------------------------------*\
   Default Styles
\*---------------------------------------*/
a {
  color: inherit;
  text-decoration: none;
  &:extend(.transition all);
  &:hover, &:active, &:focus {
    color: inherit;
    text-decoration: none;
  }
}
*[data-href], *[data-blank-href] {
  cursor: pointer;
}
h1, h2, h3, h4, h5 {
  small {
    display: block;
  }
}
p {
  display: inline-block;
  width: 100%;
  margin: 0 0 @offset 0;
}
nav {
  ul {
    &:extend(.ul_reset all);
  }
}
/*---------------------------------------*\
   Default Classes
\*---------------------------------------*/
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.align-left {
  float: left !important;
}
.align-right {
  float: right !important;
}
.align-center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.v-align {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.img_responsive {
  &:extend(.img_responsive all);
}
.clear {
  &:extend(.clear all);
}
.icon-wrap {
  display: table;
  width: auto;
  > .icon_left {
    display: table-cell;
  }
  > .icon_right {
    display: table-cell;
  }
  > div {
    display: table-cell;
    vertical-align: top;
    width: inherit;
  }
}

/*---------------------------------------*\
   Forms
\*---------------------------------------*/
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: @offset-xs;
  font-weight: 300;
  .fs(13);
  color: @white;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}
input[type="search"] {
  -webkit-appearance: none;
}
.form-group {
  margin-bottom: @offset;
}
.form-submit {
  margin: 2rem 0px 1rem;
}
.form-control {
  .fs(13);
  font-weight: 300;
  background: transparent;
  border: 1px solid @white;
  border-radius: 10px;
  color: @white;
  max-width: 260px;
  padding: (6 / @bs * 1rem) (16 / @bs * 1rem);
  line-height: (@bs + 10) / @bs * 1rem;
  width: 100%;
  height: 50 / @bs * 1rem;
  display: block;
  outline: 0 !important;
  box-shadow: none;
  -webkit-appearance: none;
  .placeholder(@white);
  &:-webkit-autofill {
    background: @white;
  }
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 1;
    background: #fafafa;
  }
  textarea& {
    min-height: 130px;
    max-width: 420px;
    height: auto !important;
    resize: none;
  }
  &:focus {
    border-color: @orange;
  }
  &:extend(.transition all);
}
.form-icon {
  position: relative;
  .form-control {
    padding-left: 35 / @bs * 1rem;
  }
  .field_icon {
    position: absolute;
    top: 10 / @bs * 1rem;
    left: 10 / @bs * 1rem;
    z-index: 10;
    color: @white;
    .fs(18);
  }
}
/*---------------------------------------*\
   Buttons
\*---------------------------------------*/
.btn, a.btn {
  .fs(13);
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  background: @orange;
  border: 2px solid @orange;
  color: @secondary;
  padding: ( 10 / @bs * 1rem) (20 / @bs * 1rem);
  display: inline-block;
  letter-spacing: 1px;
  border-radius: 8px;
  text-decoration: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  vertical-align: middle;
  &:extend(.transition all);
  .hover({
    background: lighten(@orange, 15%);
    border-color: lighten(@orange, 15%);
    color: @white;
  });
  &:active, &:focus {
    background: darken(@orange, 10%);
    border-color: darken(@orange, 10%);
    color: @white;
  }
  &.btn-o {
    background: transparent;
    color: @white;
  }
}