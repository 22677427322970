/*------------------------------------*\
   Alegreya
\*------------------------------------*/
@font-face {
  font-family: 'Alegreya';
  src: url('../fonts/Alegreya/Alegreya-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}

/*------------------------------------*\
   Sintony
\*------------------------------------*/

@font-face {
  font-family: 'Sintony';
  src: url('../fonts/Sintony/Sintony-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sintony';
  src: url('../fonts/Sintony/Sintony-Bold.ttf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}

@icomoon-font-path: "../fonts/StarFx/";

@icon-arrow: "\e900";
@icon-pin: "\e94e";
@icon-phone: "\e94f";
@icon-mys: "\e950";

@font-face {
  font-family: 'StarFx';
  src:  url('@{icomoon-font-path}/StarFx.eot?2wck6c');
  src:  url('@{icomoon-font-path}/StarFx.eot?2wck6c#iefix') format('embedded-opentype'),
  url('@{icomoon-font-path}/StarFx.ttf?2wck6c') format('truetype'),
  url('@{icomoon-font-path}/StarFx.woff?2wck6c') format('woff'),
  url('@{icomoon-font-path}/StarFx.svg?2wck6c#StarFx') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'StarFx' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow {
  &:before {
    content: @icon-arrow;
    color: #1cb5e0;
  }
}
.icon-pin {
  &:before {
    content: @icon-pin;
    color: #18a1cf;
  }
}
.icon-phone {
  &:before {
    content: @icon-phone;
    color: #18a1cf;
  }
}
.icon-mys {
  &:before {
    content: @icon-mys;
    color: #fff;
  }
}