@charset "UTF-8";
@import (reference) "_includes/_vars.less";
@import (reference) "_includes/_mixins.less";
@import "ground.less";
@import "aos.css";

/*---------------------------------------*\
   App Style
\*---------------------------------------*/
html {
  font-size: 13px;
}

/*---------------------------------------*\
   Header
\*---------------------------------------*/

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: @white;
  z-index: 100;
  background: transparent;
  .transition-default();
  &__in {
    &:extend(.container all);
  }
  &__logo {
    line-height: 1;
    padding: 2.5rem 0;
    width: 150px;
    float: left;
    text-align: center;
    transform: scale(1);
    transform-origin: center left;
    a {
      &:extend(.clear);
    }
    img.c {
      display: none;
    }
  }
  &__menu {
    display: none;
    margin-bottom: @offset;
    .fs_px(15);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: @secondary;
    text-align: center;
    &:extend(.clear);
    ul {
      display: inline-block;
      li {
        display: block !important;
        width: 100%;
        opacity: 0;
        a {
          width: 100%;
          padding: @offset @offset @offset 0;
          opacity: 1;
          color: @secondary;
          .hover({
            opacity: 0.6;
          });
        }
      }
    }
    a.btn {
      display: block;
      color: @secondary;
      @media(min-width: @screen_tablet){
        display: inline-block;
        color: @white;
        .hover({
          color: @secondary;
        });
      }
    }
    body.screen_sm &, body.screen_md &, body.screen_lg & {
      width: auto;
      display: block !important;
      position: absolute;
      right: 0;
      top: @offset;
      ul {
        li {
          opacity: 1 !important;
          display: inline-block !important;
          width: auto;
          a {
            padding: @offset-md;
            color: @white;
          }
        }
      }
    }
  }

  &__toggle {
    cursor: pointer;
    width: 38px;
    height: 35px;
    margin-right: 5px;
    position: absolute;
    top: @offset-md;
    right: @offset;
    &:extend(.not_selectable all);
    .transition-default();
    div {
      height: 3px;
      margin: 9px auto;
      width: 100%;
      background: @primary;
      .transition-default();
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.on {
      div {
        background: @secondary;
      }
      .one {
        transform: rotate(135deg);
        margin-top: 20px;
      }
      .two {
        opacity: 0;
      }
      .three {
        transform: rotate(-135deg);
        margin-top: -24px;
      }
    }
    body.screen_sm &, body.screen_md &, body.screen_lg & {
      display: none !important;
    }
  }
  .screen_sm &, .screen_xxs &, .screen_xs & {
    .header__logo img.c {
      display: none;
    }
    .header__logo img.w {
      display: inherit;
    }
  }
  .menu_active & {
    .bga(@white, 1);

    .header__logo img.w {
      display: none;
    }
    .header__logo img.c {
      display: inherit;
    }
  }
}

/*---------------------------------------*\
   sections
\*---------------------------------------*/
section {
  max-width: 1920px;
  @media(min-width: 1920px) {
    margin: auto;
  }
}

.page_banner {
  width: 100%;
  max-width: 1920px;
  max-height: 1278px;
  overflow: hidden;
  padding: 0;
  object-fit: cover;
  color: @white;
  text-align: center;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
  @media(min-width: 1920px) {
    margin: auto;
  }
  @media(max-width: 992px){
    background-size: inherit;
  }
  #num1 {
    position: absolute!important;
    bottom: -32px;
    left: -50px;
  }
  &__dots {
    width: 100%;
    float: right;
    position: relative;
    background-image: url("../files/img/bg_banner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    @media(min-width: @screen_tablet){
      width: 75%;
    }

  }
  &__card {
    width:333px;
    min-height: 333px;
    box-shadow: 0 23px 68px 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: @offset-lg @offset;
    background-color: #ffffff;
    margin: 10rem auto;
    img {
      &:extend(.img_responsive all);
      display: none;
    }
  }
  &__line {
    width: 100%;
    svg {
      position: absolute;
      left: 0;
      bottom: 0;
      top:0;
      margin: auto;
      .wt {
        fill: none;
        @media(min-width: @screen_tablet){
          fill: #fff;
        }
      }
    }
  }
}

.page_services {
  background-image: url("../files/img/circles.svg");
  background-size: contain;
  background-position: -45% center;
  background-repeat: no-repeat;
  margin: @offset-md auto;
  @media(min-width: @screen_desktop ) {
    background-position: -15% center;
  }
  &__in {
    margin: @offset-md auto;
    float: right;
  }
  &__col {
    &:extend(.grid_col all);
    width: 50%;
    @media(max-width: @screen_tablet) {
      width:100%;
    }
  }
  &__nav {
    max-width: 350px;
    float: left;
    margin: 0 @offset-xl;
    @media(max-width: @screen_tablet) {
      max-width: 100%;
      float: left;
      margin: 0 auto;
    }

      ul {
      margin: 0;
      list-style: none;
      padding: 0 @offset-md;
      li {
        padding: ( 10 / @bs * 1rem) (20 / @bs * 1rem);
        margin: @offset;
        cursor: pointer;
        position: relative;
        .transition-default();
        &:first-child {
          margin-top: 0;
        }
        span {
          width: 44px;
          height: 44px;
          display: inline-block;
          position: relative;
          top: 15px;
          left: -11px;
        }
        &:hover {
          color: @primary;
        }
        &.active {
          height: 90px;
          background-image: linear-gradient(-42deg, #1274a8 0%, #1cb5e0 100%);
          box-shadow: 0 23px 68px 16px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          color: @white;
          img {
            display: none;
          }
        }
      }
    }
  }

  &__content {
    max-width: 350px;
    float: left;
    color: @txtcolor;
    display: none!important;
    @media(max-width: @screen_tablet) {
      max-width: 100%;
      padding: 0 4rem;
    }
    img {
      width: 45px;
      height: 47px;
      display: block;
      margin: 1rem auto;
    }
    h3 {
      &:extend(.title3 all);
      color: @secondary;
      position: relative;
      &.title_one {
        &:before {
          content: '1';
          color: #FFFFFF;
          width: 26px;
          height: 26px;
          background-color: #1cb5e0;
          border-radius: 13px;
          position: absolute;
          left: -40px;
          top: 6px;
          padding: 0 10px;
        }
      }
      &.title_two {
        &:before {
          content: '2';
          color: #FFFFFF;
          width: 26px;
          height: 26px;
          background-color: #1cb5e0;
          border-radius: 13px;
          position: absolute;
          left: -40px;
          top: 6px;
          padding: 0 10px;
        }
      }
    }
    p {
      &:extend(.text all);
      color: @txtcolor;
    }
    &.current-service {
      display: inherit!important;
    }
  }

}

.page_scroll {
  text-align: center;
  a {
    width: 36px;
    height: 36px;
    margin: 7px;
    padding: 7px;
    font-size: 20px;
    margin: 10px auto;
    display: block;
    border: 1px solid @txtcolor;
    border-radius: 18px;
    transform: rotate(180deg);
  }
}
#mouse {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  a {
    z-index: 5;
    border-color: @white;
  }
}

.page_whyus {
  background-image: url("../files/img/circles.svg");
  background-size: contain;
  background-position: -45% center;
  background-repeat: no-repeat;
  color: @txtcolor;
  @media(min-width: @screen_desktop ) {
    background-position: -15% center;
  }
  &__in {
    margin: @offset-lg auto;
    float: right;
  }
  &__content {
    max-width: 500px;
    padding: 0 @offset;
    float: left;
    h2 {
      &:extend(.title3 all);
      color: @primary;
    }
    p {
      &:extend(.text all);
    }

  }
  &__image {
    float: left;
    padding: 0 @offset;
    img {
      width: 255px;
      max-width: 100%;
      height: auto;
    }
  }

}


/*---------------------------------------*\
  Contact
\*---------------------------------------*/

.page_contact {

  &__half {
    width: 50%;
    float: left;
    @media(max-width: 1085px) {
      width:100%;
    }
    &.gradient{
      background-image: linear-gradient(-42deg, #1274a8 0%, #1cb5e0 100%);
    }
    &.map{
      &:extend(.bg_set all);
      background-image: url("../files/img/map.png");
    }

  }
  &__wrap {
    padding: @offset-md @offset-lg;
    &__header {
      &:extend(.title1 all);
      font-weight: 900;
      color: @white;
    }

  }

  &__form {
    position: relative;
    margin: 4rem 0;
  }
  &__info {
    max-width: 300px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    float: right;
    margin: 2rem 8rem;
    @media(max-width: @screen_tablet) {
      max-width: 230px;
      float: right;
      text-align: center;
    }
    @media(max-width: @screen_mobile) {
      max-width: 100%;
      float: none;

    }

      li {
      margin: @offset 0;
      .item {
        .fs(15);
        color: @txtcolor;
        i {
          display: block;
          .fs(29);
        }
      }
    }
  }
  &__bgset {
    background-image: url("../files/img/contact.png");
    background-repeat: no-repeat;
    min-height: 410px;
    position: absolute;
    top: -20px;
    right: 0;
    width: 53%;
  }
  &__nav {
    max-width: 400px;
    margin: 50% auto 0;
    position: relative;
    bottom: 0;
    @media(max-width: @screen_tablet) {
      margin: @offset-lg auto 0;
      max-width: 330px;
    }

    &__logo {
      &:extend(.img_responsive all);
      max-width: 120px;
      @media(max-width: 379px){
        display: block;
        margin: @offset auto;
      }
    }
    &__menu {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      position: relative;
      bottom:35px;
      padding-left: @offset-lg;
      li {
        display: inline-block;
        a {
          color: @white;
          text-transform: uppercase;

          padding-right: @offset-md;
        }
      }
      @media(max-width: 379px){
        display: block;
        text-align: center;
        bottom: unset;
        padding: 1rem 0;
        text-align: center;
      }
    }
  }
}

/*---------------------------------------*\
   Footer
\*---------------------------------------*/
.footer {
  clear: both;
  position: relative;
  .fs(14);
  text-align: center;
  background-color: @lgrey;
  background-image: url("../files/img/bg_footer.png");
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
  &__in {
    &:extend(.container all);
    padding: @offset-md 0;
  }
  &__arrow {
    width: 50px;
    height: 50px;
    background-color: @lgrey;
    border-radius: 25px;
    position: absolute;
    top:-25px;
    left: 0;
    right: 0;
    margin: auto;
    a {
      width: 36px;
      height: 36px;
      margin: 7px;
      padding: 7px;
      font-size: 20px;
      margin: 7px auto;
      display: block;
      border: 1px solid @txtcolor;
      border-radius: 18px;
      transform: rotate(180deg);
    }
  }
  &__nav {
    margin: @offset-md 0;
    text-align: center;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      li {
        display: inline-block;
        a {
          color: @secondary;
          text-transform: uppercase;
          padding: @offset-md;
          .hover({
            color: @primary;
          });
        }
      }
    }
  }

  &__logo {
      max-width: 120 / @bs * 1rem;
      margin: @offset-md auto @offset;
    img {
      &:extend(.img_responsive all);
    }
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes bounce-10 {
  0%, 100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
  }
}
@keyframes bounce-10 {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.bounce-10 {
  -webkit-animation-name: bounce-10;
  animation-name: bounce-10;
}

@-webkit-keyframes bounce-4 {
  0%, 100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(4px);
  }
}
@keyframes bounce-4 {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
}
.bounce-4 {
  -webkit-animation-name: bounce-4;
  animation-name: bounce-4;
}

@-webkit-keyframes bounce-2 {
  0%, 100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(2px);
  }
}
@keyframes bounce-2 {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}
.bounce-2 {
  -webkit-animation-name: bounce-2;
  animation-name: bounce-2;
}

.numeric_title {
  width: 350px;
  position: relative;
  @media(min-width: @screen_desktop) {
    position: absolute;
  }
  left: -50px;
}

/*---------------------------------------*\
   Font sizes Media Query
\*---------------------------------------*/
@font_size_mobile: 13;
@font_size_tablet: 14;
@font_size_desktop: 15;
@font_size_large: 16;

/*---------------------------------------*\
   App Media Query
\*---------------------------------------*/

  #mobilesvg {
    display: block;
  }
  #desktopsvg {
    display: none;
  }
 @media (min-width: @screen_mobile) {
  html {
    font-size:unit(@font_size_mobile,px);
  }

}
@media (min-width: @screen_tablet) {
  html {
    font-size:unit(@font_size_tablet,px);
  }
  #mobilesvg {
    display: none;
  }
  #desktopsvg {
    display: block;
  }
}
@media (min-width: @screen_desktop) {
  html {
    font-size:unit(@font_size_desktop,px);
  }
}
@media (min-width: @screen_large) {
  html {
    font-size:unit(@font_size_large,px);
  }
}
